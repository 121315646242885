.circleButton-box a {
  display: flex;
  background-color: rgb(111, 178, 178);
  border-radius: 50%;
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 4em;
  font-weight: bold;
}

.circleButton-box a.active {
  background-color: rgb(255, 166, 0);
}

.circleButton-box a.active:hover {
  background-color: rgb(140, 91, 0);
}

.circleButton-box a:hover {
  background-color: rgb(77, 121, 121);
}
