.row {
  display: grid;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  grid-template-columns: 1fr 4fr 3fr 3fr 3fr 2fr 1fr;
}

input[type="checkbox"] {
  height: 15px;
  width: 15px;
}

.deleteButton {
  font-size: 30px;
}

.selected {
  background-color: rgb(198, 244, 244);
}
