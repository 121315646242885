.layout {
  display: grid;
  grid-template-rows: 15% auto;

  width: 100%;
  min-height: 100vh;
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  padding: 2% 2%;
}

.links {
  display: flex;
}

.links button {
  color: black;
  font-weight: bolder;
  font-size: 1em;
  border: black 2px solid;
  padding: 6px 12px;
  background-color: rgb(111, 178, 178);
  height: fit-content;
}

.links button:hover {
  background-color: rgb(77, 121, 121);
  cursor: pointer;
}

.logo-box {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 2em;
  font-weight: 800;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
