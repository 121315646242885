.createTaskForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5%;
  width: 80%;
}

#formName {
  margin-top: 0px;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  font-size: 3em;
  font-weight: 800;
  color: black;
  line-height: 0.8;
}

.createTaskForm button {
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 15px;
  background-color: rgb(111, 178, 178);
  font-weight: bolder;
  font-size: 1.5em;
  padding: 10px 20px;
}

.createTaskForm button:hover {
  cursor: pointer;
  background-color: rgb(77, 121, 121);
}

.taskField label {
  display: block;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 1.5em;
}

.taskField {
  width: 95%;
}

.taskField input,
.taskField select {
  width: 100%;
  height: 40px;
  padding: 0px 5px;
  background-color: rgb(111, 178, 178);
  border: none;
  font-size: 1em;
}

.errorMessage {
  color: red;
  font-size: 0.5em;
}
