.status_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.status_icon svg:hover {
  cursor: default;
}
