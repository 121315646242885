#table_box {
  width: 80%;
}

.table {
  width: 100%;
  margin: 0 auto;
  background-color: rgb(232, 233, 242);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 9px;
}

.header-row {
  display: grid;
  width: 100%;
  min-height: 50px;
  background-color: rgb(111, 178, 178);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0px 0px;
  grid-template-columns: 1fr 4fr 3fr 3fr 3fr 2fr 1fr;
  font-weight: 600;
  font-size: 1.1em;
}

.item {
  display: flex;
  align-items: center;
}

.item-1,
.item-7,
.item-6 {
  justify-content: center;
}

.save {
  display: block;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 2em;
  background-color: rgb(111, 178, 178);
  border-radius: 5%;
  margin: 30px auto;
}

.save:hover {
  cursor: pointer;
  background-color: rgb(77, 121, 121);
}

.header-row .item svg {
  margin-left: 10px;
}

.item svg:hover {
  cursor: pointer;
  color: darkslategray;
}

.no_tasks {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  text-align: center;
  margin: 20px;
}

.loader {
  position: relative;
  width: 100px;
  height: 130px;
  background: #fff;
  border-radius: 4px;
  margin-top: 40px;
}
.loader:before {
  content: "";
  position: absolute;
  width: 54px;
  height: 25px;
  left: 50%;
  top: 0;
  background-image: radial-gradient(
      ellipse at center,
      #0000 24%,
      #de3500 25%,
      #de3500 64%,
      #0000 65%
    ),
    linear-gradient(to bottom, #0000 34%, #de3500 35%);
  background-size: 12px 12px, 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  transform: translate(-50%, -65%);
  box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
}
.loader:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 66%;
  height: 60%;
  background: linear-gradient(to bottom, #f79577 30%, #0000 31%);
  background-size: 100% 16px;
  animation: writeDown 2s ease-out infinite;
}

@keyframes writeDown {
  0% {
    height: 0%;
    opacity: 0;
  }
  20% {
    height: 0%;
    opacity: 1;
  }
  80% {
    height: 65%;
    opacity: 1;
  }
  100% {
    height: 65%;
    opacity: 0;
  }
}
